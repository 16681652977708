import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import CopyNoHTML from '../../atoms/CopyNoHTML';
import CopyWithHTML from '../../atoms/CopyWithHTML';
import Image from '../../atoms/Image';


const styles = (theme) => ({
	grid__widthWrapper:{
	  padding:theme.spacing(8),
	},
	spacingOR:{
	  color: theme.palette.text.primary,
	  width: `calc(100% + (${theme.spacing(8)}*2px))`,
	  margin: `-${theme.spacing(8)}px`,
	  "& > .MuiGrid-item": {
		padding: theme.spacing(8),
	  },
	  [theme.breakpoints.only('sm')]: {
		"& > .MuiGrid-item": {
		  padding: theme.spacing(4),
		},
		"& > .MuiGrid-item:last-of-type": {
		  paddingTop: 0,
		},
	  },
	  [theme.breakpoints.down('xs')]: {
		"& > .MuiGrid-item": {
		  padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
		},
		"& > .MuiGrid-item:last-of-type": {
		  paddingTop: 0,
		},
	  },
	  [theme.breakpoints.down('md')]: {
		"& ul div:first-of-type": {
		  borderTop:0,
		},
	  },
	},
	standFirst: {
		marginBottom: theme.spacing(6),
		[theme.breakpoints.up('md')]: {
			marginBottom: theme.spacing(6),
		},
	},
  });
  
const ProfileItem = ({ theme, classes, profile }) => {
	
	const mediaPosition = profile.media_position;
	let rowDirection = "row";
	if(mediaPosition === "left") { rowDirection = "row-reverse" };

	return (
		profile.section ?
		<Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
			<CopyWithHTML copyWithHTML={profile.section} />
		</Typography>
		:
		<Box bgcolor={profile.background_colour}>
			<Container maxWidth="xl" className={classes.grid__widthWrapper}>
				<Grid
					container
					spacing={10}
					direction={rowDirection}
					classes={{ "spacing-xs-10": classes.spacingOR }}
				>
				<Grid item xs={12} md={6}>

					<Typography variant="subtitle1" component="h2" style={profile.title ? { marginBottom: theme.spacing(2)} : {} }>
						<CopyNoHTML copyNoHTML={profile.role} />
					</Typography>

					<Typography variant="h3" gutterBottom className={classNames(classes.standFirst, "standFirst")}>
						<CopyWithHTML copyWithHTML={profile.name} />
					</Typography>

					<Typography variant="body1" component="div">
						<CopyWithHTML copyWithHTML={profile.bio} />
					</Typography>

				</Grid>

				<Grid item xs={12} md={6}>
					<Image image={profile.image} />
				</Grid>

				</Grid>
			</Container>
		</Box>
	)
}

ProfileItem.propTypes = {
	classes: PropTypes.object.isRequired,
  };

export default withStyles(styles, { withTheme: true })(ProfileItem);