import { withStyles } from "@material-ui/core";
import DanmarkWoff from "./fonts/Danmark_LightWEB.woff";

const danmark = {
  fontFamily: "Danmark",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local("Danmark"),
    url(${DanmarkWoff}) format("woff")
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const styles = theme => ({
  "@global": {
    "html":{
      backgroundColor: theme.palette.primary.main,
    },
    /* Typography
    ===========================================*/
    "@font-face": [danmark],
    body: {
      fontSize: theme.typography.body1.fontSize,
    },
    ".standFirst": {
      "& em": {
        fontFamily: 'baskerville-urw, serif',
        textDecoration: "underline",
      },
      "& p": {
        marginBottom: 0,
      },
    },
    ".MuiTypography-body1": {
      "& a": {
        color: theme.palette.primary.main
      },
    },
    "div p:first-of-type": {
      marginTop: 0,
    },
    ".MuiGrid-item div:last-child > p": {
      marginBottom:0,
    },
    ".MuiGrid-item div div:last-child p": {
      marginBottom:0,
    },
    /* Fade
    =========================================*/
    ".fadeWrapper":{
      position:"absolute",
      top: "3.5rem",
      width:"100%",
      height:"120%",
      backgroundColor: theme.palette.common.white,
      zIndex: theme.zIndex.tooltip,
      [theme.breakpoints.up('sm')]: {
        top:"4rem",
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor:theme.palette.primary.main,
        "&.stories, &.home":{
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    ".fadeWrapper.showContent":{
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.4s ease-in-out, visibility 0.4s cubic-bezier(1.000, 0.000, 1.000, 0.000)"
    },
    ".fadeWrapper.hideContent":{
      opacity: 1,
      visibility: "visible",
      transition: "opacity 0s ease-in-out, visibility 0s",
    },
    /* Slick Carousel
    ==========================================*/
    ".item_1":{
      [theme.breakpoints.down('sm')]: {
        marginLeft: `${theme.spacing(2)}px !important`,
      },
    },
    "body.prevent-scroll": {
      overflow: "hidden",
    },
    ".slick-slider": {
      position: "relative",
      display: "block",
      boxSizing: "border-box",
      webkitUserSelect: "none",
      mozUserSelect: "none",
      msUserSelect: "none",
      userSelect: "none",
      webkitTouchCallout: "none",
      khtmlUserSelect: "none",
      msTouchAction: "pan-y",
      touchAction: "pan-y",
      webkitTapHighlightColor: "transparent",
    },
    ".slick-list": {
      position: "relative",
      display: "block",
      overflow: "hidden",
      margin: "0",
      padding: "0",
    },
    ".slick-list:focus": {
      outline: "none",
    },
    ".slick-list.dragging": {
      cursor: "hand",
    },
    ".slick-slider .slick-track, .slick-slider .slick-list": {
      webkitTransform: "translate3d(0, 0, 0)",
      mozTransform: "translate3d(0, 0, 0)",
      msTransform: "translate3d(0, 0, 0)",
      oTransform: "translate3d(0, 0, 0)",
      transform: "translate3d(0, 0, 0)",
    },
    ".slick-track": {
      position: "relative",
      top: "0",
      left: "0",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
    ".slick-track:before, .slick-track:after": {
      display: "table",
      content: "''",
    },
    ".slick-track:after": {
      clear: "both",
    },
    ".slick-loading .slick-track": {
      visibility: "hidden",
    },
    ".slick-slide": {
      display: "none",
      float: "left",
      height: "100%",
      minHeight: "1px",
    },
    "[dir='rtl'] .slick-slide": {
      float: "right",
    },
    ".slick-slide img": {
      display: "block",
    },
    ".slick-slide.slick-loading img": {
      display: "none",
    },
    ".slick-slide.dragging img": {
      pointerEvents: "none",
    },
    ".slick-initialized .slick-slide": {
      display: "block",
    },
    ".slick-loading .slick-slide": {
      visibility: "hidden",
    },
    ".slick-vertical .slick-slide": {
      display: "block",
      height: "auto",
      border: "1px solid transparent",
    },
    ".slick-arrow.slick-hidden": {
      display: "none",
    },
    ".slick-arrow":{
      fontSize:"3rem",
      position:"absolute",
      top:"50%",
      zIndex: theme.zIndex.appBar,
      cursor:"pointer",
    },
    ".slick-prev": {
      left:"2rem",
    },
    ".slick-next": {
      right:"2rem",
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
